<template>
  <div class="flex min-h-screen w-full items-center justify-center">
    <form class="flex w-full flex-col gap-5 px-4 sm:w-[504px]" @submit.prevent>
      <img src="@/assets/images/logo.svg" class="w-[200px]" />
      <div
        v-if="userStore.loginError"
        class="flex items-center rounded-lg bg-red-600 px-4 py-2 text-sm"
      >
        <span class="text-white">
          Something went wrong while logging you in. If the issue persists,
          contact support.
        </span>
      </div>
      <h1 class="mt-4 text-xl font-semibold tracking-wider">
        <span>Sign in</span>
      </h1>
      <label class="relative block">
        <span
          class="text-xs font-semibold"
          :class="!isValidEmail ? 'text-red-light' : 'text-gray-700'"
          >Work email</span
        >
        <input
          v-model="email"
          type="email"
          class="mt-1 block h-12 w-full rounded text-base"
          :class="{
            'text-red-light border-red-light focus:border-red-light focus:[box-shadow:none]':
              !isValidEmail,
            'border-gray-300': isValidEmail,
          }"
        />
        <div v-if="!isValidEmail" class="text-red-light absolute text-sm">
          Please enter valid email.
        </div>
      </label>
      <ZnButton
        id="start-magic-link-btn"
        type="primary"
        class="relative flex w-full items-center justify-center py-3.5 text-center text-sm font-semibold tracking-wide"
        tabindex="0"
        @click="handleMagicLink"
        @keyup.enter="handleMagicLink"
      >
        <svg
          v-if="magicLinkLoading"
          class="h-6 w-6 animate-spin fill-current text-white"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M2,12A10.94,10.94,0,0,1,5,4.65c-.21-.19-.42-.36-.62-.55h0A11,11,0,0,0,12,23c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z"
          />
        </svg>

        <span v-else>Send me a magic link</span>
      </ZnButton>
      <div
        class="bg-grays-lightest text-grays-dark flex items-center rounded-lg p-4 text-sm"
      >
        <img
          src="@/assets/images/email.svg"
          class="text-grays-light mt-0.5 h-4 w-4"
        />
        <span class="ml-3"
          >We'll email you a magic link for a password-free log in.</span
        >
      </div>
      <div v-if="isSSOLogin">
        <div class="mt-4 flex justify-center border-t border-gray-200">
          <span class="-mt-3 block bg-white px-4 text-sm text-gray-400"
            >or</span
          >
        </div>
        <GoogleSignInButton
          v-if="config.public.googleClientId"
          :id-config="{
            client_id: config.public.googleClientId,
            cancel_on_tap_outside: false,
          }"
          :btn-config="{
            width: 400,
            type: 'standard',
            theme: 'outline',
          }"
          show-one-tap
          class="mt-2 self-center"
          @authenticated="handleAuth($event)"
        />
      </div>

      <div class="fixed bottom-0 left-1/2 w-full translate-x-[-50%] px-4">
        <AppPrivacyAgreement />
      </div>
    </form>
    <zn-modal
      size="small"
      :show="showMagicLinkDialog"
      @close="showMagicLinkDialog = false"
    >
      <template #title>
        <h1 class="text-grays-darkest text-xl font-semibold">
          Check your inbox
        </h1>
      </template>
      <template #body>
        <p class="text-grays-dark mt-3">
          Magic link is on its way to {{ email }}. Follow the link we sent you
          in the email.
        </p>
        <zn-button
          class="mt-6 flex w-full items-center justify-center font-semibold"
          type="secondary"
          @click="handleMagicLink()"
        >
          <div class="relative">
            <img
              v-show="magicLinkLoading"
              src="@/assets/images/spinner.svg"
              class="absolute -left-8 h-6 w-6 animate-spin"
            />
            <span> Resend email </span>
          </div>
        </zn-button>
      </template>
    </zn-modal>
    <div v-show="ssoLoading" class="fixed inset-0 h-full w-full bg-gray-800/25">
      <div class="fixed inset-0 bottom-48">
        <zn-loading-bar class="text-teal-light" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#app'
import { useUserStore } from '@/store/user'
import ZnButton from '@/components/ZnButton/index.vue'
import ZnModal from '@/components/ZnModal/index.vue'
import { isEmailValid } from '@/utils/helper/regex'
import {
  definePageMeta,
  onMounted,
  ref,
  useRoute,
  computed,
  useGtmTrackEvent,
} from '#imports'
import { useLogin } from '~/composables/useLogin'

const config = useRuntimeConfig()

definePageMeta({
  layout: 'public',
})

const userStore = useUserStore()
const { handleRedirect } = useLogin()
const route = useRoute()

const email = ref<string>('')
const isValidEmail = ref<boolean>(true)
const showMagicLinkDialog = ref<boolean>(false)
const magicLinkLoading = ref<boolean>(false)
const ssoLoading = ref<boolean>(false)
const isSSOLogin = computed<boolean>(() => route.query?.ssoLogin === '1')
const { magicLinkClickTrackEvent } = useGtmTrackEvent()
const handleAuth = async (response: { credential: string }): Promise<void> => {
  if (response.credential) {
    ssoLoading.value = true
    userStore.credential = response.credential
    await userStore.loginWithGoogle()
    await handleRedirect()
    ssoLoading.value = false
  }
}

const startMagicLink = async () => {
  isValidEmail.value = isEmailValid(email.value)
  if (!isValidEmail.value) {
    return
  }
  magicLinkLoading.value = true
  const res = await userStore.startMagicLink({
    userEmail: email.value,
    loginType: 'magiclink.start',
  })
  magicLinkClickTrackEvent()
  magicLinkLoading.value = false
  showMagicLinkDialog.value = res
}

const handleMagicLink = async (): Promise<void> => {
  if (!magicLinkLoading.value) {
    await startMagicLink()
  }
}

onMounted(() => {
  userStore.logout()
})
</script>
