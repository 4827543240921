<template>
  <div id="googleButton" />
</template>

<script setup lang="ts">
/// <reference types='google.accounts' />
import { onMounted, type PropType } from 'vue'

const emit = defineEmits(['authenticated'])

const props = defineProps({
  idConfig: {
    type: Object as PropType<google.accounts.id.IdConfiguration>,
    required: false,
    default: () => {},
  },
  btnConfig: {
    type: Object as PropType<google.accounts.id.GsiButtonConfiguration>,
    required: false,
    default: () => {},
  },
  showOneTap: {
    type: Boolean,
    required: false,
    default: () => false,
  },
})

onMounted(() => {
  if (process.client && props.idConfig.client_id) {
    google.accounts.id.initialize({
      ...props.idConfig,
      callback: (response) => emit('authenticated', response),
    })

    const googleButton = document.getElementById('googleButton')
    if (googleButton !== null) {
      google.accounts.id.renderButton(googleButton, {
        ...props.btnConfig,
        width: 310,
      })
    }

    if (props.showOneTap) {
      google.accounts.id.prompt()
    }
  }
})
</script>
